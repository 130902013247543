import React, { useEffect, useState } from "react";
import './DeviceStatus.css'
import { Fragment } from "react";
import { format } from "date-fns";
import { Box } from "@material-ui/core";
import { commonHeaders } from "./DeviceStatusConstants";

const DisplayDeviceStatusData = ({
    deviceCategory,
    deviceStatusData = [],
    setDialog,
    setDialogContents
}) => {
    const [headers, setHeaders] = useState({});

    useEffect(() => {
        const dataHeaders = {};
        deviceStatusData?.map(d => {
            Object?.keys(d?.payload)?.forEach(h => {
                if (!dataHeaders?.hasOwnProperty(h)) {
                    dataHeaders[h] = { label: h, tooltip: "" }
                }
            })
        })
        delete dataHeaders?.ts
        delete dataHeaders?.mo
        delete dataHeaders?.ty

        setHeaders({
            ...commonHeaders,
            ...dataHeaders,
            actions: {label:"Actions",tooltip:""}
        })
    }, [deviceStatusData])

    return (
        <Fragment>
            {
                deviceCategory && deviceCategory != "" && deviceStatusData &&
                <table
                    width="100%"
                    align="center"
                    className="table mb-20 deviceStatusTable"
                >
                    <thead
                        style={{ position: "sticky", top: 0, zIndex: 50, background: "white" }}
                    >
                        <tr>
                            {Object.keys(headers).map((key, ind) => (
                                <th key={ind} title={headers[key]?.tooltip} style={{
                                    minWidth: key === "ts" ? "200px" : key === 'actions' ? '75px' : "150px", maxWidth: "300px", overflow: "hidden", textOverflow: 'ellipsis',
                                    ...(() => {
                                        if (ind === Object.keys(headers)?.length - 1) {
                                            return { display: "flex", justifyContent: "flex-end" }
                                        }
                                        else {
                                            return {}
                                        }
                                    })()
                                }}
                                >{headers[key]?.label}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {deviceStatusData.length === 0 ? (
                            <tr>
                                <td colSpan={Object.keys(headers).length}>
                                    No Data found
                                </td>
                            </tr>
                        ) : (
                            Array.isArray(deviceStatusData) && deviceStatusData?.map((element, index) => {
                                return (
                                    <tr key={index} id={index}>
                                        {Object.keys(headers).map((key) => {
                                            return (
                                                key == 'serialNumber' ?
                                                    <td className={String(element[key]).length > 60 ? "breakWord" : ""}
                                                    >
                                                        {
                                                            index + 1
                                                        }
                                                    </td>
                                                    :
                                                    key === "actions" ?
                                                        <td style={{ display: "flex", justifyContent: "flex-end" }}>
                                                            <button
                                                                className="btn btn-style-3 sm font-400"
                                                                onClick={e => {
                                                                    setDialogContents({ title: "Status Details", data: element })
                                                                    setDialog(true);
                                                                }}
                                                            >
                                                                View
                                                            </button>
                                                        </td>
                                                        :
                                                        key == 'ts' ?
                                                            <td className={String(element['payload'][key]).length > 60 ? "breakWord" : ""}
                                                            >
                                                                {
                                                                    String(element['payload'][key]) && String(element['payload'][key]).length > 0 ?
                                                                        <Box>
                                                                            {
                                                                                element?.payload?.hasOwnProperty(key) ? format(new Date(Number(element['payload'][key]) * 1000), "dd MMM yyyy, hh:mm a") : "-"
                                                                            }
                                                                        </Box>
                                                                        :
                                                                        ""
                                                                }
                                                            </td>
                                                            :
                                                            <td
                                                                className={
                                                                    String(element["payload"][key]).length > 200
                                                                        ? "breakWord"
                                                                        : ""
                                                                }
                                                            >
                                                                {element["payload"]?.hasOwnProperty(key) ? Array?.isArray(element["payload"][key]) ? element["payload"][key]?.join(", ") : typeof element["payload"][key] === 'object' ? JSON.stringify(element["payload"][key]) : element["payload"][key] : "-"}
                                                            </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </table>
            }
        </Fragment>
    );
};
export default DisplayDeviceStatusData