import {
  GET_REPORT,
  REPORT_ERROR,
  GET_DEVICE,
  GET_ALL_DEVICE,
  DEVICE_ERROR,
  REQUEST_DEVICE_USAGE,
  GET_DEVICE_USAGE,
  DEVICE_USAGE_ERROR,
  GET_FIRMWARE_DETAILS,
  DEVICE_FIRMWARE_ERROR,
  OFFBOARD_DEVICE,
  GET_LATEST_FIRMWARE,
  GET_LATEST_FIRMWARE_ERROR,
  UPDATE_SERIAL_NUMBER,
  UPDATE_SERIAL_NUMBER_ERROR,
  GET_ONBOARDED_HISTORY_DETAILS,
  GET_ONBOARDED_HISTORY_DETAILS_ERROR,
  SEND_EMAIL_RESPONSE_SUCCESS,
  SEND_EMAIL_RESPONSE_FAILED,
  SEND_MISMATCH_DATA_RESPONSE_SUCCESS,
  SEND_MISMATCH_DATA_RESPONSE_FAILED,
  GET_DIAGNOSTIC_DATA,
  GET_DIAGNOSTIC_DATA_ERROR,
  GET_FIRMWARE_INFO,
  FIRMWARE_INFO_ERROR,
  GET_METRICS_DATA,
  GET_METRICS_DATA_ERROR,
  UPDATE_FIRMWARE_VERSION_SUCCESS,
  UPDATE_FIRMWARE_VERSION_ERROR,
  GET_DEVICE_LOGS_REQUEST,
  GET_DEVICE_LOGS_FAILURE,
  GET_DEVICE_LOGS_SUCCESS,
  // USER_ERROR,
  // UPDATE_FIRMWARE_VERSION_SUCCESS,
  // UPDATE_FIRMWARE_VERSION_ERROR
} from "../action/types";

const initialState = {
  report: null,
  device: [],
  allDevice: [],
  deviceUsage: null,
  firmwareData: [],
  firmwareInfo: [],
  latestFirmwareData: [],
  updateSerialNumber: [],
  onBoardedHistoryData: [],
  emailSendingResponse: [],
  mismatchDataSendingResponse: [],
  diagnosticData: [],
  metricsData: [],
  updateFirmwareRes: [],
  metricsError: null,
  metricsLoading: true,
  loading: false,
  usageLoading: true,
  error: {},
  updateFirmwareRes: [],
  deviceLogs: []
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DEVICE_LOGS_REQUEST:
      return {
        ...state
      }
    case GET_DEVICE_LOGS_SUCCESS:
    case GET_DEVICE_LOGS_FAILURE:
      return {
        ...state,
        deviceLogs:action?.payload
      }
    case GET_REPORT:
      return {
        ...state,
        report: payload,
        loading: false,
      };
    // case USER_ERROR:
    //   return {
    //     report: initialState,
    //   };
    case REPORT_ERROR:
      return {
        ...state,
        report: payload,
        loading: false,
      };
    case GET_DEVICE:
      return {
        ...state,
        device: payload,
        loading: false,
      };
    case GET_ALL_DEVICE:
      return {
        ...state,
        allDevice: payload,
        loading: false,
      };
    case DEVICE_ERROR:
      return {
        ...state,
        device: payload,
        loading: false,
      };
    case REQUEST_DEVICE_USAGE:
      return {
        ...state,
        usageLoading: true,
      };
    case GET_DEVICE_USAGE:
      return {
        ...state,
        deviceUsage: payload,
        usageLoading: false,
      };
    case DEVICE_USAGE_ERROR:
      return {
        ...state,
        deviceUsage: payload,
        usageLoading: false,
      };
    case GET_FIRMWARE_DETAILS:
    case DEVICE_FIRMWARE_ERROR:
      return {
        ...state,
        firmwareData: payload,
        loading: false,
      };
    case GET_FIRMWARE_INFO:
    case FIRMWARE_INFO_ERROR:
      return {
        ...state,
        firmwareInfo: payload,
        loading: false,
      };
    case OFFBOARD_DEVICE:
      return {
        ...state,
        device: [],
        loading: false,
      };
    case GET_LATEST_FIRMWARE:
    case GET_LATEST_FIRMWARE_ERROR:
      return {
        ...state,
        latestFirmwareData: payload,
        loading: false,
      };
    case UPDATE_SERIAL_NUMBER:
    case UPDATE_SERIAL_NUMBER_ERROR:
      return {
        ...state,
        updateSerialNumber: payload,
        loading: false,
      };
    case GET_ONBOARDED_HISTORY_DETAILS:
    case GET_ONBOARDED_HISTORY_DETAILS_ERROR:
      return {
        ...state,
        onBoardedHistoryData: payload,
        loading: false,
      };
    case SEND_EMAIL_RESPONSE_SUCCESS:
    case SEND_EMAIL_RESPONSE_FAILED:
      return {
        ...state,
        emailSendingResponse: payload,
        loading: false,
      };
    case SEND_MISMATCH_DATA_RESPONSE_SUCCESS:
    case SEND_MISMATCH_DATA_RESPONSE_FAILED:
      return {
        ...state,
        mismatchDataSendingResponse: payload,
        loading: false,
      };
    case GET_DIAGNOSTIC_DATA:
    case GET_DIAGNOSTIC_DATA_ERROR:
      return {
        ...state,
        diagnosticData: payload,
        loading: false,
      };
    case GET_METRICS_DATA:
      return {
        ...state,
        metricsData: payload,
        metricsError: null,
        metricsLoading: false,
      };
    case GET_METRICS_DATA_ERROR:
      return {
        ...state,
        metricsData: null,
        metricsError: payload,
        metricsLoading: false,
      };
    case UPDATE_FIRMWARE_VERSION_SUCCESS:
      return {
        ...state,
        updateFirmwareRes: payload
      };
    case UPDATE_FIRMWARE_VERSION_ERROR:
      return {
        ...state,
        updateFirmwareRes: payload
      };

    default:
      return state;
  }
}
