export const deviceDiagnosticsPageSize = 10;
export const startDateLimit = 89;
export const ACDiagnosticHeaders = {
    ACv_o: { label: "AC Voltage (V)", tooltip: "AC Voltage available at Input Power Supply" },
    DCv_o: { label: "DC Voltage (V)", tooltip: "DC Voltage of Power rail" },
    Falt_a: { label: "Fault types", tooltip: "Fault Types" },
    Flvl_i: { label: "IDU Fan Speed (0..6)", tooltip: "IDU Fan Speed" },
    Tr_i: { label: 'Air Temp (°C)', tooltip: 'Indoor Room Air Temperature' },
    Te_i: { label: 'Pipe Temp (°C)', tooltip: "Evaporator Pipe Temperature" },
    Tu_i: { label: 'Set Temp (°C)', tooltip: "Set Temperature by User" },
    Frpm_i: { label: 'Motor Speed (RPM)', tooltip: "Speed of IDU Fan Motor" },
    PM25_i: { label: 'Indoor PM2.5 (ug/m3)', tooltip: "Indoor PM2.5 Sensor Reading" },
    Fsta_i: { label: 'Filter Status', tooltip: "Filter Status" },
    Tsu_o: { label: 'Suction Pipe Temp (°C)', tooltip: "Suction Pipe Temperature" },
    Ta_o: { label: 'Outdoor Air Temp (°C)', tooltip: "Outdoor Air Temperature" },
    Tc_o: { label: 'Condenser Pipe Temp (°C)', tooltip: "Condenser Pipe Temperature" },
    Frpm_o: { label: 'Fan Speed (RPM)', tooltip: "Outdoor Fan Speed" },
    Pcur_o: { label: 'DC Current (A)', tooltip: "DC Current of U,V,W Phase to compressor" },
    ACc_o: { label: 'AC Current (A)', tooltip: "Total Input AC Current Taken by Outdoor Unit" },
    Td_o: { label: 'Top Pipe Temp (°C)', tooltip: "Compressor Top Pipe Temperature" },
    Tipm_o: { label: 'Temp of IPM (°C)', tooltip: "Temperature of IPM (Driving Circuit of Compressor)" },
    Trpm_c: { label: 'Algorithm RPM of Comp.', tooltip: "The target RPM of Compressor decided by Algorithm" },
    Srpm_c: { label: 'Set RPM of Comp.', tooltip: "The RPM of Compressor set as per current situation" },
    Rrpm_c: { label: 'Realtime RPM of Comp.', tooltip: "Realtime RPM of Compressor on which it is running" },
    Lrpm_a: { label: 'Limit on RPM of Comp.', tooltip: "Reason due to Which RPM of Comp. has been Limited" },
    Prot_a: { label: 'Temporary Protections', tooltip: 'Temporary Protections due to which the Unit has stopped working' },
    POn_t: { label: 'Total P On Time (sec)', tooltip: "Total Power On Time" },
    V: { label: "Version", tooltip: "Version" },
    in_s: { label: "Interval time (sec)", tooltip: 'Interval time' },
}
export const commonHeaders = {
    // serialNumber: "S.No",
    ts: { label: "Timestamp", tooltip: "" },
    st: { label: "Start Time", tooltip: "" }
};