import axios from "axios";
import customConfig from "../config";
const ResponseInterceptor = () => {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (
        error.response &&
        401 === error.response.status &&
        window.location.pathname !== "/"
      ) {
        alert(customConfig.sessionExpireMsg);
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
        localStorage.removeItem("userRoles");
        localStorage.removeItem("userLevel");
        localStorage.removeItem("loggedInUserName");
        window.location = "/";
      }
      else if (error && !error.response && error.message == "Network Error" && window.location.pathname !== "/" && !window?.location?.host?.includes("localhost")) {

        // This is temproary else if condition is added for UM1 Token expiry or invalid UM1 token. 
        // We'll remove this code later if we move to UM2 Completely.
        // Reason- When invalid token is used to UM1 API, it is returning CORS Error instead of 401. 
        // but this is not the case if we call DM API with UM1 invalid token 

        alert(customConfig.sessionExpireMsg);
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
        localStorage.removeItem("userRoles");
        localStorage.removeItem("userLevel");
        localStorage.removeItem("loggedInUserName");
        window.location = "/";
      }
      else {
        return Promise.reject(error);
      }
    }
  );
};

export default ResponseInterceptor;
