import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { setAlert } from "../../action/alert";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SendNotification from "./sendNotification";
// import QRCodeFilter from "./qrCodeCountFilter";
import { cancelNotificationTask, createNotificationTask, getNotificationTask, searchNotifications, searchNotificationsByUserId } from "../../action/notification";
import FindNotification from "./findNotification";
import { Box, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { getNotificationPermission, notificationStatusMoreHeaders } from "./notificationConstant";
import { getFormatedJSON } from "../../utils/general";

const useStyles = makeStyles({
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    selectValues: {
        width: "100%",
    },
    activeTab: {
        border: '1px solid #bdbdbd',
        backgroundColor: 'gray',
        textTransform: 'capitalize'
    },
    nonActiveTab: {
        border: 'none',
        backgroundColor: 'none',
        textTransform: 'capitalize'
    },
    "Dialog": {
        zIndex: "2400 !important",
        "& .MuiDialogTitle-root": {
            backgroundColor: "#f4f4f4",
            "& h2": {
                fontSize: "16px !important"
            }
        },
        "& .MuiDialogContent-root": {
            padding: "24px",
            minWidth: "800px",
            maxWidth: "800px"
        },
        "& .buttonContainer": {
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "16px"
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "800px"
        },
        "& .dialogContents": {
            maxWidth: "800px",
            minHeight: "280px",
            maxHeight: "240px",
            overflowY: "auto"
        }
    },
});

const headers = {
    productSerialNumber: "Product Serial Number",
    status: "QR Code Print Status",
    reasonOfFailure: "Reason of Failure"
};

const NotificationManagement = ({
    wrapperClass = "right-content-wraper",
    allowedDeviceCategories,
    allowedCompanyBrand,
    allowedCountries,
    setAlert,
    notificationStore,
    getNotificationTask,
    createNotificationTask,
    cancelNotificationTask,
    searchNotificationsByUserId,
    searchNotifications,
    userData,
    tenantData,
    userRoles,
    userLevel,
}) => {
    const classes = useStyles()
    const [activeTab, setActiveTab] = React.useState(0)
    const [dialog, setDialog] = useState(false);
    const [dialogTab, setDialogTab] = useState(0);
    const [dialogContents, setDialogContents] = useState({ title: "", data: {}, content: "" })

    return (
        <div className={wrapperClass}>
            {(!getNotificationPermission(userRoles, 'sendNotification') && !(getNotificationPermission(userRoles, 'getNotificationTask') || getNotificationPermission(userRoles, 'getNotificationList'))) ? <div>
                <h2>Insufficient Permission</h2>
            </div>
                :
                <div className="row">
                    <Tabs
                        value={activeTab}
                        onChange={(e, newValue) => {
                            setActiveTab(newValue)
                        }}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "#bdbdbd"
                            }
                        }}
                        style={{ marginTop: '20px' }}
                    >
                        {getNotificationPermission(userRoles, 'sendNotification') &&
                            <Tab label="Send Notification" className={activeTab == 0 ? classes.activeTab : classes.nonActiveTab} />

                        }
                        {
                            (getNotificationPermission(userRoles, 'getNotificationTask') || getNotificationPermission(userRoles, 'getNotificationList')) &&
                            <Tab label="Search Notification" className={activeTab == 1 ? classes.activeTab : classes.nonActiveTab} />
                        }
                    </Tabs>
                    {
                        activeTab === 0 ?
                            <SendNotification
                                allowedDeviceCategories={allowedDeviceCategories}
                                allowedCompanyBrand={allowedCompanyBrand}
                                allowedCountries={allowedCountries}
                                setAlert={setAlert}
                                notificationStore={notificationStore}
                                createNotificationTask={createNotificationTask}
                                getNotificationTask={getNotificationTask}
                                setDialog={setDialog}
                                setDialogContents={setDialogContents}
                                setDialogTab={setDialogTab}
                                userLevel={userLevel}
                            ></SendNotification>
                            :
                            <FindNotification
                                notificationData={notificationStore}
                                getNotificationTask={getNotificationTask}
                                searchNotificationsByUserId={searchNotificationsByUserId}
                                allowedCountries={allowedCountries}
                                searchNotifications={searchNotifications}
                                userData={userData}
                                tenantData={tenantData}
                                setDialog={setDialog}
                                setDialogContents={setDialogContents}
                                setDialogTab={setDialogTab}
                                userRoles={userRoles}
                            ></FindNotification>
                    }
                </div>}
            <Dialog
                className={classes?.Dialog}
                open={dialog}
            >
                <DialogTitle >
                    {dialogContents?.title}
                </DialogTitle>
                <DialogContent>
                    {
                        dialogContents?.content?.toLowerCase()?.includes("notificationdetails") ?
                            <>
                                <Tabs
                                    value={dialogTab}
                                    onChange={(e, newValue) => {
                                        setDialogTab(newValue)
                                    }}
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "#bdbdbd"
                                        }
                                    }}
                                >
                                    <Tab label="Details" className={dialogTab == 0 ? classes.activeTab : classes.nonActiveTab} />
                                    <Tab label="JSON" className={dialogTab == 1 ? classes.activeTab : classes.nonActiveTab} />
                                </Tabs>
                                <Box >
                                    {
                                        dialogTab === 0 ?
                                            <Box className="dialogContents">
                                                {dialogContents?.data && dialogContents?.content !== "userNotificationDetails" && <table
                                                    width="100%"
                                                    align="center"
                                                    className="table style-4 mb-20 notificationDataTable"
                                                >
                                                    <thead
                                                        style={{ position: "sticky", top: 0, zIndex: 50, background: "white" }}
                                                    >
                                                        <tr>
                                                            {Object.keys(notificationStatusMoreHeaders).map((key) => (
                                                                <th>{notificationStatusMoreHeaders[key]?.label}</th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr style={{ background: "white" }}>
                                                            {
                                                                Object.keys(notificationStatusMoreHeaders).map((key) => {
                                                                    return (
                                                                        <td
                                                                            className={
                                                                                String(dialogContents?.data[key]).length > 200
                                                                                    ? "breakWord"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            {dialogContents?.data?.hasOwnProperty(key) ? dialogContents?.data[key] : "-"}
                                                                        </td>
                                                                    );
                                                                })}
                                                        </tr>

                                                    </tbody>
                                                </table>
                                                }
                                                {dialogContents?.data && <>
                                                    <div style={{ marginTop: "8px" }} className="page-title">
                                                        <h3>Task ID</h3>
                                                    </div>
                                                    <div>{dialogContents?.data?.fullTaskId}</div>
                                                    <div style={{ marginTop: "8px" }} className="page-title">
                                                        <h3>Message</h3>
                                                    </div>
                                                    <Box>
                                                        {getFormatedJSON(dialogContents?.data?.msg)}
                                                    </Box>
                                                </>}

                                            </Box>
                                            :
                                            <Box className="dialogContents">
                                                {getFormatedJSON(dialogContents?.data?.payload)}
                                            </Box>
                                    }
                                    <Box className="buttonContainer grey-theme">
                                        <button
                                            style={{ marginLeft: "8px", }}
                                            className="btn btn-style-3 sm font-400"
                                            onClick={(e) => { setDialog(false) }}
                                        >
                                            Close
                                        </button>
                                    </Box>
                                </Box></>
                            :
                            <>
                                <Box>
                                    {`Are you sure you want to cancel the notification with task ID ${dialogContents?.data?.fullTaskId} ?`}
                                </Box>
                                <Box className="buttonContainer grey-theme">
                                    <button
                                        style={{ marginLeft: "8px", }}
                                        className="btn btn-style-3 sm font-400"
                                        onClick={(e) => { setDialog(false) }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        style={{ marginLeft: "8px", backgroundColor: "#0062b0" }}
                                        className="btn btn-style-3  sm font-400"
                                        onClick={(e) => {
                                            setDialog(false)
                                            cancelNotificationTask(dialogContents?.data?.fullTaskId, () => {
                                                getNotificationTask(dialogContents?.data?.fullTaskId)
                                            })
                                        }}
                                    >
                                        Confirm
                                    </button>
                                </Box></>

                    }


                </DialogContent>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    allowedDeviceCategories: state.auth.allowedDeviceCategories,
    allowedCompanyBrand: state.auth.allowCompanyBrand,
    allowedCountries: state.auth.allowedCountries,
    userLevel: state.auth.userLevel,
    userRoles: state.auth.roles,
    notificationStore: state.notification,
    userData: state.user,
    tenantData: state?.tenant?.tenantInfo
});

const mapDispatchToProps = (dispatch) => ({
    setAlert: (message, status) => dispatch(setAlert(message, status)),
    createNotificationTask: (requestData) => dispatch(createNotificationTask(requestData)),
    getNotificationTask: (taskId, action = "updateGetNotification") => dispatch(getNotificationTask(taskId, action)),
    searchNotificationsByUserId: (userId, pgIndex, severity = null) => dispatch(searchNotificationsByUserId(userId, pgIndex, severity)),
    searchNotifications: (searchBy, searchStr, pgIndex, tenantId, token) => dispatch(searchNotifications(searchBy, searchStr, pgIndex, tenantId, token)),
    cancelNotificationTask: (taskId, cb) => dispatch(cancelNotificationTask(taskId, cb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationManagement);