export const notificationConstants = {
    oneTimeScheduleType: "scheduleOneTime",
    repeatScheduleType: "scheduleTypeRepeat",

    iosPlatform: "iosPlatform",
    androidPlatform: "androidPlatform",
    notifySeverityInfo: "info",
    notifySeverityAlert: "alert",
    notifySeverityRecommendation: "recommendation",
    notifySeverityPromotion: "promotion",

}
export const notificationChannelTypes = {
    pushNotification: { value: "push", label: "Push Notification" },
    // smsNotification: { value: "sms", label: "SMS Notification" },
    // emailNotification: { value: "email", label: "E-mail Notification" },
}

export const notificationValidations = {
    email: {
        subject: { maxLength: 200 },
        html: { maxLength: 200 },
        text: { maxLength: 200 },
    },
    push: {
        body: { maxLength: 500 }
    },
    sms: {
        body: { maxLength: 200 }
    }
}

export const notificationDetailsRefreshRate = 10000;

export const notificationStatusHeaders = {
    taskId: { label: "Task Id" },
    expectedUserCount: { label: "Total User Count" },
    userCount: { label: "Success User Count" },
    // successCount: "Success Count",
    failureCount: { label: "Failure Count" },
    taskStatus: { label: "Task Status" },
    action: { label: "Actions", style: { display: 'flex', justifyContent: "flex-end" } }
};

export const notificationStatusMoreHeaders = {
    notificationType: { label: "Type" },
    severity: { label: "Severity" },
    createdAt: { label: "Created At" },
    expiryDate: { label: "Expiry" },
};

export const notificationListPageSize = 20;

export const notificationCountryMapping = {
    IN: "ind",
    BD: "BGD",
    LK: "LKA",
    SG: "SGP",
    TH: "THA",
    JP: "JP",
    NP: "NP"
}

export const getNotificationPermission = (userRoles, operation, type = "role", userLevel) => {
    const roleBasedpermissions = {
        sendNotification: userRoles?.includes("CUSTOMERSUPPORTL3") || userRoles?.includes("CUSTOMERSUPPORTL3SPECIAL") || userRoles?.includes("CUSTOMERSUPPORTL4") || userRoles?.includes("CLOUDADMIN"),
        getNotificationList: userRoles?.includes('CUSTOMERSUPPORTL4') || userRoles?.includes('CLOUDADMIN') || userRoles?.includes('SYSTEM_PUSH_NOTIFICATION'),
        getNotificationTask: userRoles?.includes('CUSTOMERSUPPORTL2') || userRoles?.includes('CUSTOMERSUPPORTL3') || userRoles?.includes('CUSTOMERSUPPORTL4') || userRoles?.includes('CLOUDADMIN') || userRoles?.includes('CUSTOMERSUPPORTL3SPECIAL'),
        cancelNotification: userRoles?.includes("CUSTOMERSUPPORTL2") || userRoles?.includes("CUSTOMERSUPPORTL3") || userRoles?.includes("CUSTOMERSUPPORTL4") || userRoles?.includes("CLOUDADMIN") || userRoles?.includes("CUSTOMERSUPPORTL3SPECIAL")
    }
    const levelBasedPermissions = {
        userCountrySelection: userLevel >= 4,
        userLoginSelection: userLevel >= 4,
        userCategorySelection: userLevel >= 4
    }
    return type === 'level' ? levelBasedPermissions?.hasOwnProperty(operation) ? levelBasedPermissions[operation] : false : roleBasedpermissions?.hasOwnProperty(operation) ? roleBasedpermissions[operation] : false
}