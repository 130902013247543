import React, { Fragment, useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { setAlert } from "../../../action/alert";
import './DeviceConnDisconnLogs.css'
import { Box } from "@material-ui/core";
import { format } from "date-fns";


const DeviceConnDisconnLogs = ({
    deviceDetails,
    getDeviceConnDisconnLogsData,
    connectionStatusLogs,
}) => {

    const [deviceConnDisconnLogsData, setDeviceConnDisconnLogsData] = useState([]);
    const [updateFlag, setUpdateFlag] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const headers = {
        serialNumber: "S.No",
        onlineStatus: 'Status',
        timestamp: 'Timestamp',
    };

    useEffect(() => {
        if (deviceDetails && deviceDetails instanceof Object && deviceDetails.hasOwnProperty('deviceId')) {
            getDeviceConnDisconnLogs()
        }
    }, [deviceDetails])

    useEffect(() => {
        setDeviceConnDisconnLogsData(connectionStatusLogs);
    }, [connectionStatusLogs])

    useEffect(() => {
        if (updateFlag) {
            getDeviceConnDisconnLogs()
        }
    }, [updateFlag])

    const filter = () => {
        setUpdateFlag(Math.random());
    }
    const getDeviceConnDisconnLogs = () => {
        setIsLoading(true)
        getDeviceConnDisconnLogsData(deviceDetails?.deviceId ?? "", () => {
            setIsLoading(false);

        });
    }
    if (isLoading) {
        return (
            <div
                style={{
                    display: "grid",
                    placeItems: "center",
                    height: "80vh",
                }}
            >
                <CircularProgress size="5em" style={{ color: "#AFB42B" }} />
            </div>
        );
    }
    return (
        <Fragment>
            <div className="content-block bottom-shadow py-15 mb-30">
                <div className="row mb-0">
                    <div className="col s12">
                        <div
                            className="table-wrapper mb-20"
                            style={{
                                scrollBehavior: "auto",
                            }}
                        >
                            <table
                                width="100%"
                                align="center"
                                className="table style-4 mb-20 deviceConnDisconnLogsTable"
                            >
                                <thead
                                    style={{ position: "sticky", top: 0, zIndex: 50, background: "white" }}
                                >
                                    <tr >
                                        {Object.keys(headers).map((key, ind) => (
                                            <th key={ind} style={ind === Object.keys(headers)?.length - 1 ? { display: "flex", justifyContent: "flex-end" } : {}}
                                            >{headers[key]}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {deviceConnDisconnLogsData && deviceConnDisconnLogsData.length === 0 ? (
                                        <tr>
                                            <td colSpan={Object.keys(headers).length}>
                                                No Data found
                                            </td>
                                        </tr>
                                    ) : (
                                        deviceConnDisconnLogsData && deviceConnDisconnLogsData.length > 0 && deviceConnDisconnLogsData.map((element, index) => {
                                            return (
                                                <tr key={index} id={index}>
                                                    {Object.keys(headers).map((key) => {
                                                        return (
                                                            key == "serialNumber" ?
                                                                <td className={String(element[index]).length > 200 ? "breakWord" : ""}>
                                                                    {index + 1}
                                                                </td>
                                                                :
                                                                key == "timestamp" ?
                                                                    <td style={{ display: "flex", justifyContent: "flex-end" }} className={String(element[index]).length > 200 ? "breakWord" : ""}>
                                                                        {

                                                                            element?.hasOwnProperty(key) ? <Box>
                                                                                {
                                                                                    format(new Date(element[key]), "dd MMM yyyy, hh:mm a")
                                                                                }
                                                                            </Box> : "-"}
                                                                    </td>
                                                                    :
                                                                    key == "onlineStatus" ?
                                                                        <td
                                                                            className={
                                                                                String(element[key]).length > 200
                                                                                    ? "breakWord"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            {element[key] == true
                                                                                ? <p style={{ "color": "green", "fontWeight": 'bold' }}>Online</p>
                                                                                : <p style={{ "color": "red", "fontWeight": 'bold' }}>Offline</p>}
                                                                        </td>
                                                                        :
                                                                        <td
                                                                            className={
                                                                                String(element[key]).length > 200
                                                                                    ? "breakWord"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            {element[key] ? element[key] : ""}
                                                                        </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
    setCustomAlert: (message, alertType) => dispatch(setAlert(message, alertType))
})
export default connect(mapStateToProps, mapDispatchToProps)(DeviceConnDisconnLogs);
