import React, { useEffect, useState } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import './DeviceStatus.css'
import { Fragment } from "react";
import DatePicker from "react-datepicker";
import { deviceStatusPageSize, startDateLimit } from "./DeviceStatusConstants";
import DisplayDeviceStatusData from "./DisplayDeviceStatusData";
import { format } from "date-fns";

const DeviceStatus = ({
    deviceDetails,
    getDeviceStatusData,
    statusData,
    downloadDeviceStatusReport,
    setDialog,
    setDialogContents
}) => {
    const [deviceStatusData, setDeviceStatusData] = useState([]);
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showDeviceStatusDataList, setShowDeviceStatusDataList] = useState(false);
    const [isDataDownloading, setIsDataDownloading] = useState(false)
    const [pageIndex, setPageIndex] = useState(0);

    const getNextPage = () => {
        const newPg = pageIndex + 1
        setPageIndex(newPg);
        fetchDeviceStatusData(newPg)
    }

    const getPrevPage = () => {
        const newPg = pageIndex - 1;
        setPageIndex(newPg);
        fetchDeviceStatusData(newPg)
    }

    const fetchDeviceStatusData = (page = pageIndex, fromTs = formateDate(startDateFilter), toTs = formateDate(endDateFilter)) => {
        setIsLoading(true)
        getDeviceStatusData({
            deviceId: deviceDetails?.deviceId ?? "",
            fromTs,
            toTs,
            index: page,
            size: deviceStatusPageSize,
        }, () => {
            setIsLoading(false);
            setShowDeviceStatusDataList(true)
        })
    };
    const reset = () => {
        setStartDateFilter(new Date())
        setEndDateFilter(new Date())
        setPageIndex(0);
        const todaysDateTime = new Date(new Date()?.setHours(0, 0, 0, 0))
        fetchDeviceStatusData(0, formateDate(todaysDateTime), formateDate(new Date()));
    };
    const filter = () => {
        setPageIndex(0);
        fetchDeviceStatusData(0);
    };
    const handleStartDateChange = (date) => {
        setStartDateFilter(date)
        setEndDateFilter(null);
        setShowDeviceStatusDataList(false)
    }
    const handleEndDateChange = (date) => {
        setEndDateFilter(date);
        setShowDeviceStatusDataList(false)
    }
    function handleDownloadDeviceStatusData() {
        setIsDataDownloading(true)
        downloadDeviceStatusReport({
            fromTs: formateDate(startDateFilter),
            toTs: formateDate(endDateFilter),
            deviceId: deviceDetails.deviceId,
            printableStartDate: `${format(startDateFilter, "dd MMM yyyy")}-${format(endDateFilter, "dd MMM yyyy")}`
        }, () => {
            setIsDataDownloading(false)
        })
    }
    const formateDate = (dateFilter) => {
        if (dateFilter) {
            return dateFilter.getTime()
        }
    }

    useEffect(() => {
        M.AutoInit();
    }, [isLoading, deviceStatusData]);

    useEffect(() => {
        setStartDateFilter(new Date());
        setEndDateFilter(new Date());
        setIsLoading(true);
        const todaysDateTime = new Date(new Date()?.setHours(0, 0, 0, 0))
        getDeviceStatusData({ deviceId: deviceDetails?.deviceId, fromTs: formateDate(todaysDateTime), toTs: Date?.now(), page: 0, size: deviceStatusPageSize },
            () => {
                setIsLoading(false);
                setShowDeviceStatusDataList(true)
            });
        setPageIndex(0);
    }, [deviceDetails?.deviceId])

    useEffect(() => {
        setDeviceStatusData(statusData)
    }, [statusData])

    return (
        <Fragment>
            <div className="container">
                <div className="content-block bottom-shadow">
                    <div className="py-15 ">
                        <div className="row mb-0">
                            <div className="col" style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ marginRight: "8px" }}>
                                    From:
                                </div>
                                <DatePicker
                                    selected={startDateFilter}
                                    minDate={(() => {
                                        const futureDate = new Date();
                                        return new Date(futureDate?.setDate(futureDate?.getDate() - startDateLimit))
                                    })()}
                                    maxDate={new Date()}
                                    onChange={handleStartDateChange}
                                    placeholderText="Start Date"
                                    dateFormat='MM/dd/yyyy'
                                    className="add-input calendar input_phone"
                                />
                            </div>
                            <div className="col" style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ marginRight: "8px" }}>
                                    To:
                                </div>
                                <DatePicker
                                    selected={endDateFilter}
                                    minDate={startDateFilter}
                                    maxDate={new Date()}
                                    onChange={handleEndDateChange}
                                    placeholderText="End Date"
                                    dateFormat='MM/dd/yyyy'
                                    className="add-input calendar input_phone"
                                />
                            </div>
                            <div className="col pt-10" style={{ marginLeft: '30px' }}>
                                <button
                                    className="btn btn-style-3  sm mr-10 font-400"
                                    onClick={filter}
                                    disabled={startDateFilter === null || endDateFilter === null}
                                >
                                    Filter
                                </button>
                                <button
                                    className="btn btn-style-3 sm font-400 mr-10"
                                    onClick={reset}
                                >
                                    Reset
                                </button>
                                <button
                                    className="btn btn-style-3  sm font-400 float_right cursor-Hand"
                                    onClick={handleDownloadDeviceStatusData}
                                    disabled={isDataDownloading || (startDateFilter === null || endDateFilter === null)}
                                >
                                    {
                                        isDataDownloading ? <Fragment>
                                            <CircularProgress size="2em" style={{ color: "#AFB42B", marginTop: "5px" }} />
                                        </Fragment>
                                            : "Download"
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isLoading && <div
                        style={{
                            display: "grid",
                            placeItems: "center",
                            height: "20vh",
                        }}
                    >
                        <CircularProgress size="5em" style={{ color: "#AFB42B" }} />
                    </div>
                }
                {
                    !isLoading && showDeviceStatusDataList && <Fragment>
                        <div className="content-block bottom-shadow py-15 mb-30">
                            <div className="row mb-0">
                                <div className="col s12">
                                    <div
                                        className="table-wrapper mb-20"
                                        style={{
                                            overflowX: "scroll",
                                            scrollBehavior: "auto",
                                            overflowY: "scroll",
                                        }}
                                    >
                                        <DisplayDeviceStatusData
                                            setDialog={setDialog}
                                            setDialogContents={setDialogContents}
                                            deviceCategory={deviceDetails.category}
                                            deviceStatusData={deviceStatusData}
                                        />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <button
                                            className="btn btn-style-3  sm  mr-10 font-400"
                                            onClick={() => getPrevPage()}
                                            disabled={pageIndex === 0}
                                        >
                                            Previous
                                        </button>
                                        <button
                                            className="btn btn-style-3  sm  mr-10 font-400"
                                            onClick={() => getNextPage()}
                                            disabled={deviceStatusData?.length < deviceStatusPageSize}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }
            </div>
        </Fragment>
    );
};

export default DeviceStatus;