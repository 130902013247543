import React, { Fragment, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  getDevice,
  getDeviceUsagebyId,
  getFirmwareHistory,
  getOnboardedHistory,
  getDiagnosticsData,
} from "../../action/report";
import { makeStyles } from '@material-ui/core/styles';
import { getUserById, clearUserById } from "../../action/user";
import { getWarrantyInfo, clearWarrantyData } from "../../action/warranty";
import Alert from "../layout/Alert";
import M from "materialize-css/dist/js/materialize.min.js";
import DeviceDetails from "../device/DeviceDetails";
import UserInfo from "../dashboard//UserInfo";
import customConfig from "../../config";
import BarcodeScanner from "../Common/scanner/BarcodeScanner";
import { getQRDetailsUsingScanner } from "../../action/qrcode";
import { setAlert } from "../../action/alert";
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DeviceDACLogs from "./DeviceDACLogs";
import DeviceUpdateLogs from "./DeviceUpdateLogs";
import DeviceDiagnostics from "./DeviceDiagnostics/DeviceDiagnostics";
import DeviceConnDisconnLogs from "./DeviceConnDisconn/DeviceConnDisconnLogs";
import { downloadDeviceDiagnosticReport, downloadDeviceStatusReport, getDeviceConnDisconnLogsData, getDeviceDiagnosticsData, getDeviceStatusData } from "../../action/devicelogs";
import DeviceStatus from "./DeviceStatus/DeviceStatus";
import { getFormatedJSON } from "../../utils/general";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";

const useStyles = makeStyles({
  selectInput: {
    height: "3rem",
    width: "100%"
  },
  selectValues: {
    width: "100%",
  },
  activeTab: {
    border: '1px solid #bdbdbd',
    backgroundColor: '#e0e0e0',
    textTransform: 'capitalize'
  },
  nonActiveTab: {
    border: 'none',
    backgroundColor: 'none',
    textTransform: 'capitalize'
  },
  TabContainer: {
    "& .MuiTabs-fixed": {
      overflow: 'auto !important',
      width: '100%'
    },
  },
  "Dialog": {
    zIndex: "2400 !important",
    "& .MuiDialogTitle-root": {
      backgroundColor: "#f4f4f4",
      "& h2": {
        fontSize: "16px !important"
      }
    },
    "& .MuiDialogContent-root": {
      padding: "24px",
      minWidth: "800px",
      maxWidth: "800px"
    },
    "& .buttonContainer": {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "16px"
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "800px"
    },
    "& .dialogContents": {
      maxWidth: "800px",
      minHeight: "280px",
      maxHeight: "240px",
      overflowY: "auto"
    }
  },
});

const SearchDevice = ({
  setAlert,
  deviceId,
  getDevice,
  getDeviceUsagebyId,
  getFirmwareHistory,
  getUserById,
  clearUserById,
  getOnboardedHistory,
  getWarrantyInfo,
  clearWarrantyData,
  getDiagnosticsData,
  getDeviceDiagnosticsData,
  downloadDeviceDiagnosticReport,
  getDeviceConnDisconnLogsData,
  getDeviceStatusData,
  downloadDeviceStatusReport,
  DeviceData: {
    device,
    deviceUsage,
    latestFirmwareData,
    firmwareData,
    onBoardedHistoryData,
    diagnosticData,
    deviceLogs,
    report
  },
  UserData: { userById, umV1AuthToken },
  wrapperClass = deviceId ? "" : "right-content-wraper",
  searchBarWrapperClass = "",
  userRoleInfo,
  tenantInfo
}) => {

  const classes = useStyles()
  const [activeTab, setActiveTab] = React.useState(0)

  const history = useHistory();
  const searchInitiated = useRef(false);
  const [text, setText] = useState("");
  const [showText, setShowText] = useState(false);
  const [searchBy, setSearchBy] = useState(customConfig.deviceSearchByDeviceId);
  const [searchByPlaceHolder, setSearchByPlaceHolder] =
    useState("Enter Device Id");
  const [withoutOnboard, setWithoutOnboard] = useState(false);

  const [displayScanner, setDisplayScanner] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [dialogContents, setDialogContents] = useState({ title: '', data: {} });

  useEffect(() => {
    M.AutoInit();
  }, []);
  useEffect(() => {


    if (deviceId) {
      clearUserById();
      setText(deviceId);
      setShowText(false);
      setWithoutOnboard(false);
      device = null;
      searchInitiated.current = true;
      getDevice(deviceId, customConfig.deviceSearchByDeviceId, userRoleInfo, umV1AuthToken, tenantInfo.tenantId);
    }
    else {
      if (!showText && history.location.state) {
        setShowText(true);
      }
    }
  }, []);

  useEffect(() => {
    if (text.length > 0 && device && device.length > 0) {
      const userId = device[0].onboardedById;

      if (!(userRoleInfo.level == 0 && userRoleInfo.roles.includes(customConfig.roleFactoryAdmin))) {
        if (userId && userId.length > 0) {
          getUserById(userId, umV1AuthToken, tenantInfo.tenantId);
        } else {
          setWithoutOnboard(true);
        }
      }

      const devId = device[0].deviceId;

      if (!(userRoleInfo.level == 0 && userRoleInfo.roles.includes(customConfig.roleFactoryAdmin))) {
        getFirmwareHistory(devId);
        getOnboardedHistory(devId);
        getDiagnosticsData(devId);
        getDeviceUsagebyId(devId);
      }

      setShowText(true);
      setText("");
    }
  }, [device]);

  useEffect(() => {
    if (searchInitiated.current) {
      // Get eWarranty auth token and Product List
      if (userById && userById.mobile) {
        getWarrantyInfo(userById.mobile);
      } else {
        clearWarrantyData();
      }
    }
  }, [userById]);

  const onSubmit = (e) => {
    e.preventDefault(true);
    setActiveTab(0)
    clearUserById();
    setShowText(false);
    setWithoutOnboard(false);
    device = null;
    searchInitiated.current = true;
    getDevice(text, searchBy, userRoleInfo, umV1AuthToken, tenantInfo.tenantId);
  };

  const onChange = (e) => setText(e.target.value);

  const changeSearchBy = (e) => {
    setSearchBy(e.target.value);
    setText("");
    setSearchByPlaceHolder(
      `Enter ${e.target.options[e.target.selectedIndex].text}`
    );
    device = {};
    setShowText(false);
  };

  const handleNotifyOffboard = () => {
    setShowText(false);
  };

  function handleScanQRcodeAndBarcode() {
    setDisplayScanner(!displayScanner)
  }

  function handleQRCodeScanDetails(data) {
    clearUserById();
    setShowText(false);
    setWithoutOnboard(false);
    device = null;

    if (data.toString().substring(0, 2) == "MT") {
      //qrcode
      getQRDetailsUsingScanner(data)
        .then((qrCodeScannedResult) => {
          if (qrCodeScannedResult.productSerialNumber) {
            setText(qrCodeScannedResult.productSerialNumber)
            getDevice(qrCodeScannedResult.productSerialNumber, customConfig.deviceSearchByProductSerialNumber, userRoleInfo, umV1AuthToken, tenantInfo.tenantId)
          }
          else {
            setAlert("Cannot Scan QR Code. Scanned QR Code is Model ID Based QR Code", "danger")
          }

        })
        .catch((error) => {
          setAlert("Failed to Scan QR Code", "danger")
        })
    }
    else {
      // scanned data is product serial number using barcode
      setText(data)
      getDevice(data, customConfig.deviceSearchByProductSerialNumber, userRoleInfo, umV1AuthToken, tenantInfo.tenantId);
    }
  }

  return (
    <Fragment>
      <div className={wrapperClass}>
        <div className="container">
          <div className="row">
            <div className="s12 col">
              <div className="content-block bottom-shadow search-layout-2 mb-0">
                <div className="p-15">
                  <div className="row srch-device-reset mb-0">
                    <div className="s4 col">
                      <div className="page-title">
                        <label className="black-text font-600">
                          Search Device
                        </label>
                        <div className="sub-title d-none">
                          Search Device and Owner
                        </div>
                      </div>
                    </div>

                    <div className="s4 col">
                      <div className="control-capabilities">
                        <div className="model-srch reset">
                          <label className="field-name active">Search By</label>
                          <select
                            className="custom-select select-reset"
                            name="searchBy"
                            value={searchBy}
                            onChange={(e) => changeSearchBy(e)}
                          >
                            <option value={customConfig.deviceSearchByDeviceId}>
                              Device Id
                            </option>
                            <option
                              value={customConfig.deviceSearchByMacAddress}
                            >
                              Mac Address
                            </option>
                            <option
                              value={
                                customConfig.deviceSearchByProductSerialNumber
                              }
                            >
                              Product Serial No.
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="s4 col">
                      <form className="from" onSubmit={onSubmit}>
                        <div className="search-device not-found right">
                          <i className="material-icons search-icon"> search</i>
                          <input
                            className="add-input notransform border-0 "
                            placeholder={searchByPlaceHolder}
                            type="text"
                            name="text"
                            value={text}
                            onChange={onChange}
                          />
                          <input
                            type="submit"
                            value="Search"
                            className="btn orange-bg btn-block md no-shadow"
                          />
                          <div style={{ marginLeft: '15px' }}>
                            <i class="fa fa-qrcode fa-3x" aria-hidden="true" onClick={() => {
                              handleScanQRcodeAndBarcode()
                            }} style={{ cursor: 'pointer', color: 'black' }} title="Click to Scan QR Code or Barcode"></i>
                          </div>
                        </div>
                      </form>
                    </div>

                    {/* QR Code and Barcode Scanner */}
                    <div>
                      <div className="s4 col"></div>
                      <div className="s4 col"></div>
                      <div className="s4 col">
                        {
                          displayScanner &&
                          <Fragment>

                            <BarcodeScanner scanDetailsHandler={handleQRCodeScanDetails}></BarcodeScanner>
                            <label>QR Code or Barcode Scanner</label>
                          </Fragment>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mid-seprator"></div>
          <Alert />
        </div>
      </div>
      <div>
        {showText && device && Object.keys(device).length > 0 && (
          <div
            style={{ marginTop: "0px", paddingTop: "0px" }}
            className={wrapperClass}
          >
            <div className="container">
              <div className="device-detail-wrap device-info">
                {!(userRoleInfo.level == 0 && userRoleInfo.roles.includes(customConfig.roleFactoryAdmin)) &&
                  (userById || withoutOnboard) && (
                    <div className="row mb-0">
                      <UserInfo
                        user={userById}
                        withoutOnboard={withoutOnboard}
                      ></UserInfo>
                    </div>
                  )
                }
                <div className={classes?.TabContainer}>
                  <Tabs
                    value={activeTab}
                    onChange={(e, newValue) => {
                      setActiveTab(newValue)
                    }}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#bdbdbd",
                        float: 'right'
                      }
                    }}
                    style={{ marginTop: '20px'}}
                  >
                    <Tab
                      label="Device Info"
                      className={activeTab == 0 ? classes.activeTab : classes.nonActiveTab}
                      style={{ border: '1px solid black' }}
                    />
                    <Tab
                      label="DAC Logs"
                      className={activeTab == 1 ? classes.activeTab : classes.nonActiveTab}
                      style={{ border: '1px solid black' }}
                    />
                    <Tab
                      label="Device Logs"
                      className={activeTab == 2 ? classes.activeTab : classes.nonActiveTab}
                      style={{ border: '1px solid black' }}
                    />
                    <Tab
                      label="Connection Status Logs"
                      className={activeTab == 3 ? classes.activeTab : classes.nonActiveTab}
                      style={{ border: '1px solid black' }}
                    />
                    <Tab
                      label="Diagnostic Logs"
                      className={activeTab == 4 ? classes.activeTab : classes.nonActiveTab}
                      style={{ border: '1px solid black' }}
                    />
                    <Tab
                      label="Status Logs"
                      className={activeTab == 5 ? classes.activeTab : classes.nonActiveTab}
                      style={{ border: '1px solid black' }}
                    />
                  </Tabs>
                </div>
                <div hidden={activeTab != 0}>
                  <div className="row mb-0">
                    <div className="s12 p-0 col">
                      <div className="content-block position-relative">
                        <div className="p-15">
                          <DeviceDetails
                            device={device}
                            deviceUsage={deviceUsage}
                            latestFirmwareData={latestFirmwareData}
                            firmwareData={firmwareData}
                            onBoardedHistoryData={onBoardedHistoryData}
                            diagnosticData={diagnosticData}
                            notifyOffboard={handleNotifyOffboard}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  (() => {
                    switch (activeTab) {
                      case 1:
                        return <DeviceDACLogs
                          deviceDetails={device ? device.length > 1 ? device[1] : device[0] : {} ?? {}}
                        />
                      case 2:
                        return <DeviceUpdateLogs
                          deviceDetails={device ? device.length > 1 ? device[1] : device[0] : {} ?? {}}
                        />
                      case 3:
                        return <DeviceConnDisconnLogs
                          connectionStatusLogs={deviceLogs}
                          getDeviceConnDisconnLogsData={getDeviceConnDisconnLogsData}
                          deviceDetails={device ? device.length > 1 ? device[1] : device[0] : {} ?? {}}
                        />
                      case 4:
                        return <DeviceDiagnostics
                          setDialog={setDialog}
                          setDialogContents={setDialogContents}
                          diagnosticData={deviceLogs}
                          deviceDetails={device ? device.length > 1 ? device[1] : device[0] : {} ?? {}}
                          getDeviceDiagnosticsData={getDeviceDiagnosticsData}
                          downloadDeviceDiagnosticReport={downloadDeviceDiagnosticReport}
                        />
                      case 5:
                        return <DeviceStatus
                          setDialog={setDialog}
                          setDialogContents={setDialogContents}
                          statusData={deviceLogs}
                          deviceDetails={device ? device.length > 1 ? device[1] : device[0] : {} ?? {}}
                          getDeviceStatusData={getDeviceStatusData}
                          downloadDeviceStatusReport={downloadDeviceStatusReport}
                        />
                    }
                  })()
                }
              </div>
            </div>
          </div>
        )}
      </div>
      <Dialog
        className={classes?.Dialog}
        open={dialog}
      >
        <DialogTitle>
          {dialogContents?.title}
        </DialogTitle>
        <DialogContent>
          <div className="dialogContents">
            {getFormatedJSON(dialogContents?.data)}
          </div>
          <div className="buttonContainer grey-theme">
            <button
              style={{ marginLeft: "8px", }}
              className="btn btn-style-3 sm font-400"
              onClick={(e) => { setDialog(false) }}
            >
              Close
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment >
  );
};

const mapStateToProps = (state) => ({
  DeviceData: state.report,
  UserData: state.user,
  userRoleInfo: {
    level: state.auth.userLevel,
    roles: state.auth.userRoles,
  },
  tenantInfo: state.tenant.tenantInfo,
});

const mapDispatchToProps = (dispatch) => ({
  getDevice: (arg1, arg2, arg3, arg4, arg5) => dispatch(getDevice(arg1, arg2, arg3, arg4, arg5)),
  getDeviceUsagebyId: (arg1) => dispatch(getDeviceUsagebyId(arg1)),
  getFirmwareHistory: (arg1) => dispatch(getFirmwareHistory(arg1)),
  getOnboardedHistory: (arg1) => dispatch(getOnboardedHistory(arg1)),
  getUserById: (arg1, arg2, arg3) => dispatch(getUserById(arg1, arg2, arg3)),
  clearUserById: () => dispatch(clearUserById()),
  getWarrantyInfo: (arg1) => dispatch(getWarrantyInfo(arg1)),
  clearWarrantyData: () => dispatch(clearWarrantyData()),
  getDiagnosticsData: (arg1) => dispatch(getDiagnosticsData(arg1)),
  setAlert: (message, status) => dispatch(setAlert(message, status)),
  getDeviceConnDisconnLogsData: (deviceId, cb) => { dispatch(getDeviceConnDisconnLogsData(deviceId, cb)) },
  getDeviceDiagnosticsData: (params, cb) => { dispatch(getDeviceDiagnosticsData(params, cb)) },
  downloadDeviceDiagnosticReport: (params, cb) => { dispatch(downloadDeviceDiagnosticReport(params, cb)) },
  getDeviceStatusData: (params, cb) => { dispatch(getDeviceStatusData(params, cb)) },
  downloadDeviceStatusReport: (params, cb) => { dispatch(downloadDeviceStatusReport(params, cb)) }

})

export default connect(mapStateToProps, mapDispatchToProps)(SearchDevice);
