import React, { Fragment, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../../components/routing/Routes";
import { connect } from "react-redux";
import customConfig from "../../config";

const LeftNav = ({ auth, tenantInfo }) => {
  const location = useLocation();

  const userInfo = {
    level: auth.userLevel,
    roles: auth.roles
  };
  return (
    <div>
      <div className="left-navbar fixed round-style radius-16">
        <div className="vertical-block">
          <ul className="left-navigation">
            {
              (userInfo.roles.includes(customConfig.roleLineTestUser)) ?
                <li>
                  <Link
                    to="/dashboard"
                    className={
                      location.pathname === "/dashboard" ||
                        location.pathname === "/dashboardBack"
                        ? "active"
                        : ""
                    }
                  >
                    <i className="left-icon device-info"></i>Device Info
                  </Link>
                </li>
                :
                <Fragment>
                  {
                    (userInfo.level == 0 && (userInfo.roles.includes(customConfig.roleFactoryAdmin))) ||
                      userInfo.roles.includes(customConfig.roleFieldOperator) ? <li>
                      <Link
                        to="/dashboard"
                        className={
                          location.pathname === "/dashboard" ||
                            location.pathname === "/dashboardBack"
                            ? "active"
                            : ""
                        }
                      >
                        <i className="left-icon device-info"></i>Device Info
                      </Link>
                    </li>
                      :
                      <li>
                        <Link
                          to="/dashboard"
                          className={
                            location.pathname === "/dashboard" ||
                              location.pathname === "/dashboardBack"
                              ? "active"
                              : ""
                          }
                        >
                          <i className="left-icon user-info"></i>User Info
                        </Link>
                      </li>
                  }
                  {
                    userInfo.level > 0 && (!userInfo.roles.includes(customConfig.roleFieldOperator)) && <li>
                      <Link
                        to="/searchDevice"
                        className={
                          location.pathname.indexOf("/searchDevice") !== -1
                            ? "active"
                            : ""
                        }
                      >
                        <i className="left-icon device-info"></i>
                        Device Info
                      </Link>
                    </li>
                  }
                  {(userInfo.level > 0 || userInfo.roles.includes(customConfig.roleFactoryAdmin)) &&
                    (!userInfo.roles.includes(customConfig.roleFieldOperator)) &&
                    <li>
                      <Link
                        to="/deviceDetail"
                        className={
                          location.pathname.indexOf("/deviceDetail") !== -1
                            ? "active"
                            : ""
                        }
                      >
                        <i className="left-icon device-model"></i>
                        Device Model
                      </Link>
                    </li>
                  }
                  {(userInfo.level > 4 && (!userInfo.roles.includes(customConfig.roleFieldOperator))) && (<li>
                    <Link
                      to="/deviceFirmware"
                      className={
                        location.pathname.indexOf("/deviceFirmware") !== -1
                          ? "active"
                          : ""
                      }
                    >
                      <i className="left-icon device-model"></i>
                      Device Firmware
                    </Link>
                  </li>)}
                  {(userInfo.level > 4 || userInfo.roles.includes(customConfig.roleCloudAdmin) ||
                    userInfo.roles.includes(customConfig.roleFactoryAdmin)) &&
                    (!userInfo.roles.includes(customConfig.roleFieldOperator)) &&
                    <li>
                      <Link
                        to="/idueepromConfig"
                        className={
                          location.pathname.indexOf("/idueepromConfig") !== -1
                            ? "active"
                            : ""
                        }
                      >
                        <i className="left-icon device-model"></i>
                        IDU EEPROM Config
                      </Link>
                    </li>
                  }
                  {(userInfo.level > 2 && (tenantInfo.tenantId === "panasonic") && (!userInfo.roles.includes(customConfig.roleFieldOperator))) &&
                    <li>
                      <Link
                        to="/statistics"
                        className={
                          location.pathname.indexOf("/statistics") !== -1
                            ? "active"
                            : ""
                        }
                      >
                        <i className="left-icon statistics"></i>
                        Statistics
                      </Link>
                    </li>
                  }
                  {(userInfo.level > 2 && (tenantInfo.tenantId === "panasonic") && (!userInfo.roles.includes(customConfig.roleFieldOperator))) &&
                    <li>
                      <Link
                        to="/productMetrics"
                        className={
                          location.pathname.indexOf("/productMetrics") !== -1
                            ? "active"
                            : ""
                        }
                      >
                        <i className="left-icon metrics"></i>
                        Product Metrics
                      </Link>
                    </li>
                  }
                  {userInfo.level > 4 && (!userInfo.roles.includes(customConfig.roleFieldOperator)) &&
                    <li>
                      <Link
                        to="/fotaJobs"
                        className={
                          location.pathname.indexOf("/fotaJobs") !== -1
                            ? "active"
                            : ""
                        }
                      >
                        <i className="left-icon mqtt-fota"></i>
                        Manage MQTT FOTA
                      </Link>
                    </li>
                  }
                  {(userInfo.level > 4 ||
                    userInfo.roles.includes(customConfig.roleCloudAdmin) ||
                    userInfo.roles.includes(customConfig.roleFactoryAdmin)
                  ) && (!userInfo.roles.includes(customConfig.roleFieldOperator)) &&
                    <li>
                      <Link
                        to="/manufacturedDevices"
                        className={
                          location.pathname.indexOf("/manufacturedDevices") !== -1
                            ? "active"
                            : ""
                        }
                      >
                        <i className="left-icon device-model"></i>
                        Manufactured Devices
                      </Link>
                    </li>
                  }
                  {/* {userInfo.level > 2 && (!userInfo.roles.includes(customConfig.roleFieldOperator)) &&
                    <li>
                      <Link
                        to="/firmwareInfo"
                        className={
                          location.pathname.indexOf("/firmwareInfo") !== -1
                            ? "active"
                            : ""
                        }
                      >
                        <i className="left-icon metrics"></i>
                        Firmware Info
                      </Link>
                    </li>
                  } */}
                  {userInfo.level > 3 && (!userInfo.roles.includes(customConfig.roleFieldOperator)) &&
                    <li>
                      <Link
                        to="/feedback"
                        className={
                          location.pathname.indexOf("/feedback") !== -1 ? "active" : ""
                        }
                      >
                        <i className="left-icon feedback"></i>
                        Feedback
                      </Link>
                    </li>
                  }
                  {/* {userInfo.level > 3 && (!userInfo.roles.includes(customConfig.roleFieldOperator)) &&
                    <li>
                      <Link
                        to="/metricsChart"
                        className={
                          location.pathname.indexOf("/metricsChart") !== -1 ? "active" : ""
                        }
                      >
                        <i className="left-icon metrics-chart"></i>
                        Metrics Chart
                      </Link>
                    </li>
                  } */}
                  {
                    (userInfo.level > 4 ||
                      userInfo.roles.includes(customConfig.roleCloudAdmin) ||
                      userInfo.roles.includes(customConfig.roleFactoryAdmin)) &&
                    (!userInfo.roles.includes(customConfig.roleFieldOperator)) &&
                    <li>
                      <Link
                        to="/qrcode"
                        className={
                          location.pathname.indexOf("/qrcode") !== -1
                            ? "active"
                            : ""
                        }
                      >
                        <i className="left-icon device-model"></i>
                        QR Code
                      </Link>
                    </li>
                  }
                  {
                    (userInfo.level > 4 || userInfo.roles.includes(customConfig.roleCloudAdmin) ||
                      userInfo.roles.includes(customConfig.roleFactoryAdmin)) &&
                    (!userInfo.roles.includes(customConfig.roleFieldOperator)) &&
                    <li>
                      <Link
                        to="/deviceDealer"
                        className={
                          location.pathname.indexOf("/deviceDealer") !== -1
                            ? "active"
                            : ""
                        }
                      >
                        <i className="left-icon device-model"></i>
                        Dealer Management
                      </Link>
                    </li>
                  }
                </Fragment>
            }
            {userInfo.level >= 2 && <li>
              <Link
                to="/notification"
                className={
                  location.pathname.indexOf("/notification") !== -1
                    ? "active"
                    : ""
                }
              >
                <i className="left-icon notification"></i>
                Notifications
              </Link>
            </li>}
          </ul>
          {/* <div className="copy-wrap">
            <div className="easter-eggs">
              Designed and Developed by Panasonic India Innovation Center
            </div>
            <div className="copyright-block">
              Copyright © 2020 Panasonic India Pvt Ltd.
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tenantInfo: state.tenant.tenantInfo,
});

export default connect(mapStateToProps, {})(LeftNav);
