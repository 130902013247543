import React, { Fragment } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DatePicker from "react-datepicker";
import Checkbox from '@material-ui/core/Checkbox';

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { getCountryCodeISO } from "../../utils/general";
import { getNotificationPermission } from "./notificationConstant";


const useStyles = makeStyles({
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    selectValues: {
        width: "100%",
    },
    activeTab: {
        border: '1px solid #bdbdbd',
        backgroundColor: '#e0e0e0',
        textTransform: 'capitalize'
    },
    nonActiveTab: {
        border: 'none',
        backgroundColor: 'none',
        textTransform: 'capitalize'
    },
});

const headers = {
    productSerialNumber: "Product Serial Number",
    status: "QR Code Print Status",
    reasonOfFailure: "Reason of Failure"
};

const UserSelection = ({
    allowedDeviceCategories,
    allowedCompanyBrand,
    allowedCountries = [],
    setAlert,
    country,
    deviceOwnership,
    userStartLastLogin,
    userEndLastLogin,
    handleUserCountries,
    handleUserDeviceOwnership,
    handleUserStartLastLogin,
    handleUserEndLastLogin,
    uploadedUserIdFileInput,
    userIdCSVFileInputRef,
    handleUserIdFileInput,
    userLevel
}) => {

    const classes = useStyles()

    // Adjust the height of menu items in select. Otherwise get hidden behind headers
    const menuProps = {
        style: {
            marginTop: '52px'
        },
    };

    const allDeviceOwnershipSelected = allowedDeviceCategories.length === deviceOwnership.length

    const filter = createFilterOptions();

    const handleUploadedDeviceOwnership = (event, selectedOptions, reason) => {
        if (reason === "select-option" || reason === "remove-option") {
            if (selectedOptions.find((option) => option === "Select All")) {

                if (!allDeviceOwnershipSelected) {
                    handleUserDeviceOwnership([...allowedDeviceCategories]);
                } else {
                    handleUserDeviceOwnership([]);
                }
            } else {
                handleUserDeviceOwnership(selectedOptions)
            }
        } else if (reason === "clear") {
            handleUserDeviceOwnership([]);
        }
    };

    const handleUserDeviceOwnershipRenderer = (option, { selected }) => {
        const selectAllProps =
            option === "Select All"
                ? { checked: allDeviceOwnershipSelected }
                : {};
        return (
            <Fragment>
                <Checkbox
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    {...selectAllProps}
                />
                {option || "Select All"}
            </Fragment>
        );
    };


    const getOptionSelected = (option, anotherOption) =>
        option === anotherOption;

    return (
        <Fragment>
            <label className="title" style={{ fontSize: "14px", fontWeight: "bold" }}>Users</label>
            <div className="content-block position-relative" style={{ marginTop: "10px" }}>
                <div className="py-15">
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            Upload User Ids CSV File
                        </Grid>
                        <Grid item xs={4} style={{ width: "100% important" }}>
                            <input
                                className="md border-1"
                                placeholder="Upload User Ids List"
                                accept=".csv"
                                type="file"
                                {...(() => {
                                    if (uploadedUserIdFileInput === "") {
                                        return { value: "" }
                                    }
                                    else {
                                        return {}
                                    }
                                })()}
                                style={{ width: "100% !important" }}
                                name={uploadedUserIdFileInput?.name ?? uploadedUserIdFileInput}
                                onChange={(e) => {
                                    handleUserIdFileInput(e.target.files[0])
                                }}
                                ref={userIdCSVFileInputRef}
                            />
                        </Grid>
                    </Grid>
                    {getNotificationPermission([], "userCountrySelection", 'level', userLevel) && <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Country: </label>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl variant="outlined" className={classes.selectValues}>
                                <InputLabel id="select-outlined-label-country-name" className="title">Select Country</InputLabel>
                                <Select
                                    labelId="select-outlined-label-country-name"
                                    id="select-outlined-country-name-id"
                                    value={country}
                                    onChange={(e) => {
                                        handleUserCountries(e.target.value)
                                    }}
                                    label="Select Country"
                                    className={classes.selectInput}
                                    MenuProps={menuProps}
                                >
                                    <MenuItem key={"None"} value={"None"}>{"None"}</MenuItem>
                                    {
                                        allowedCountries?.map(country => {
                                            return <MenuItem key={country} value={getCountryCodeISO(country.toUpperCase())}>{getCountryCodeISO(country.toUpperCase())}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>}
                    {getNotificationPermission([], "userCategorySelection", 'level', userLevel) && <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Device Ownership: </label>
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                                multiple
                                size="small"
                                limitTags={10}
                                options={allowedDeviceCategories}
                                value={deviceOwnership}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.toString()}
                                renderOption={handleUserDeviceOwnershipRenderer}
                                onChange={handleUploadedDeviceOwnership}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Select Device Ownerships" placeholder="Search" />
                                )}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    return ["Select All", ...filtered];
                                }}
                                getOptionSelected={getOptionSelected}
                                ListboxProps={{ style: { maxHeight: 250 } }}
                            />
                        </Grid>
                    </Grid>}
                    {getNotificationPermission([], "userLoginSelection", 'level', userLevel) && <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Last Login: </label>
                        </Grid>
                        <Grid item xs={3} >
                            <DatePicker
                                selected={userStartLastLogin}
                                maxDate={new Date()}
                                onChange={(date) => {
                                    if (date) {
                                        handleUserStartLastLogin(date.getTime())
                                    }
                                    else {
                                        handleUserStartLastLogin(date)
                                    }
                                }}
                                placeholderText="Start Login Date"
                                dateFormat='MM/dd/yyyy'
                                className="add-input calendar input_phone"
                            />
                        </Grid>
                        <Grid item xs={3} >
                            <DatePicker
                                selected={userEndLastLogin}
                                maxDate={new Date()}
                                onChange={(date) => {
                                    if (date) {
                                        handleUserEndLastLogin(date.getTime())
                                    }
                                    else {
                                        handleUserEndLastLogin(date)
                                    }
                                }}
                                placeholderText="End Login Date"
                                dateFormat='MM/dd/yyyy'
                                className="add-input calendar input_phone"
                            />
                        </Grid>
                    </Grid>}
                </div>
            </div>
        </Fragment>
    );
}


export default UserSelection