import axios from "axios";
import { setAlert } from "./alert";
import {
  GET_USER,
  USER_ERROR,
  GET_USER_BY_ID,
  USER_ERROR_BY_ID,
  UPDATE_EMAIL_CLEAR,
  UPDATE_EMAIL_ERROR,
  UPDATE_EMAIL_SUCCESS,
} from "./types";
import { getHome } from "./home";
import customConfig from "../config";
import env from "../env";
//GET Search User

export const getCurrentUser = (dispatch) => (searchKey, searchBy, umV1AuthToken, tenantId) => {
  dispatch({
    type: GET_USER,
    payload: null,
  });
  return new Promise(async (resolve, reject) => {
    try {
      let config = {}
      if (env.GET_UM_MOBILE_USERS_VERSION() == customConfig.umV2Version) {
        config = {
          headers: {
            "Content-Type": "application/json"
          },
        };
      }
      else {
        config = {
          headers: {
            "Content-Type": "application/json",
            "X-Tenant-ID": tenantId,
            "x-auth-token": umV1AuthToken,
            "authorization": `Bearer ${umV1AuthToken}`
          },
        };
      }

      let emailId;
      let mobileNum;
      if ((!searchKey || searchKey.length < 5) && searchBy === "mobile") {
        //Since we are passing country code, mobile number will never be empty
        dispatch(setAlert(customConfig.deviceMobileSearchMsg, "danger"));
        reject(customConfig.deviceMobileSearchMsg);
      } else if (!searchKey && searchBy === "email") {
        dispatch(setAlert(customConfig.deviceEmailSearchMsg, "danger"));
        reject(customConfig.deviceEmailSearchMsg);
      } else if (/\S+@\S+\.\S+/.test(searchKey) && searchBy === "email") {
        emailId = searchKey;
        mobileNum = null;

        if (env.GET_UM_MOBILE_USERS_VERSION() == customConfig.umV1Version) {
          let result = await axios
            .get(
              `${env.GET_USERMANAGEMENT_API_URL()}/simplifi/v1/userManagement/users/search?email=${emailId}`,
              config
            )
            .then((res) => {
              dispatch(getHome(res.data.userId));
              dispatch({
                type: GET_USER,
                payload: res.data,
              });
              resolve(res.data);
            });
        }
        else {
          let result = await axios
            .get(
              `${env.GET_USERMANAGEMENT_API_URL_V2()}/simplifi/v2/userManagement/users/search?email=${emailId}`,
              config
            )
            .then((res) => {
              dispatch(getHome(res.data.userId));
              dispatch({
                type: GET_USER,
                payload: res.data,
              });
              resolve(res.data);
            });
        }

        // localStorage.setItem("userInfo", JSON.stringify(res.data));
      } else if (
        /^\+[1-9]{1}[0-9]{3,14}$/.test(searchKey) &&
        searchBy === "mobile"
      ) {
        emailId = null;
        mobileNum = searchKey;
        if (env.GET_UM_MOBILE_USERS_VERSION() == customConfig.umV1Version) {
          let result = await axios
            .get(
              `${env.GET_USERMANAGEMENT_API_URL()}/simplifi/v1/userManagement/users/search?mobile=${encodeURIComponent(
                mobileNum
              )}`,
              config
            )
            .then((res) => {
              dispatch(getHome(res.data.userId));
              dispatch({
                type: GET_USER,
                payload: res.data,
              });
              resolve(res.data);
            });
        }
        else {
          let result = await axios
            .get(
              `${env.GET_USERMANAGEMENT_API_URL_V2()}/simplifi/v2/userManagement/users/search?mobile=${encodeURIComponent(
                mobileNum
              )}`,
              config
            )
            .then((res) => {
              dispatch(getHome(res.data.userId));
              dispatch({
                type: GET_USER,
                payload: res.data,
              });
              resolve(res.data);
            });
        }

        // localStorage.setItem("userInfo", JSON.stringify(res.data));
      } else {
        dispatch(setAlert(customConfig.deviceInvalidMsg, "danger"));
        reject(customConfig.deviceInvalidMsg);
      }
    } catch (err) {
      dispatch(
        setAlert(
          err.response && err.response.data
            ? err.response.data.message
            : customConfig.serverDownMsg,
          "danger"
        )
      );
      dispatch({
        type: USER_ERROR,
        payload: console.log("error", err),
      });
      reject(err);
    }
  });
};

export const getUserById = (userId, umV1AuthToken, tenantId) => async (dispatch) => {
  dispatch({
    type: GET_USER_BY_ID,
    payload: null,
  });
  try {
    let config = {}
    if (env.GET_UM_MOBILE_USERS_VERSION() == customConfig.umV2Version) {
      config = {
        headers: {
          "Content-Type": "application/json"
        },
      };
    }
    else {
      config = {
        headers: {
          "Content-Type": "application/json",
          "X-Tenant-ID": tenantId,
          "x-auth-token": umV1AuthToken,
          "authorization": `Bearer ${umV1AuthToken}`
        },
      };
    }
    let res = {}
    if (env.GET_UM_MOBILE_USERS_VERSION() == customConfig.umV1Version) {
      res = await axios.get(
        `${env.GET_USERMANAGEMENT_API_URL()}/simplifi/v1/userManagement/users/userId?userId=${userId}`,
        config
      );
    }
    else {
      res = await axios.get(
        `${env.GET_USERMANAGEMENT_API_URL_V2()}/simplifi/v2/userManagement/users/userId?userId=${userId}`,
        config
      );
    }

    res.data.userId = userId;
    dispatch({
      type: GET_USER_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    if (!err.response) {
      dispatch(setAlert(customConfig.serverDownMsg, "danger"));
    } else {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: USER_ERROR_BY_ID,
      payload: err,
    });
  }
};

export const clearUserById = () => async (dispatch) => {
  dispatch({
    type: GET_USER_BY_ID,
    payload: null,
  });
};

//Update end user's email id based on userId
export const updateEmailIDbyUserID = (userId, email, umV1AuthToken, tenantId) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_EMAIL_CLEAR,
    });
    let config = {}
    if (env.GET_UM_MOBILE_USERS_VERSION() == customConfig.umV2Version) {
      config = {
        headers: {
          "Content-Type": "application/json"
        },
      };
    }
    else {
      config = {
        headers: {
          "Content-Type": "application/json",
          "X-Tenant-ID": tenantId,
          "x-auth-token": umV1AuthToken,
          "authorization": `Bearer ${umV1AuthToken}`
        },
      };
    }
    const body = {
      email,
      userId,
    };
    let res = {}
    if (env.GET_UM_MOBILE_USERS_VERSION() == customConfig.umV1Version) {
      res = await axios.post(
        `${env.GET_USERMANAGEMENT_API_URL()}/simplifi/v1/userManagement/updateUserEmail`,
        body,
        config
      );
    }
    else {
      res = await axios.post(
        `${env.GET_USERMANAGEMENT_API_URL_V2()}/simplifi/v2/userManagement/updateUserEmail`,
        body,
        config
      );
    }

    dispatch({
      type: UPDATE_EMAIL_SUCCESS,
    });
  } catch (err) {
    dispatch(
      setAlert(
        err.response && err.response.data
          ? err.response.data.message
          : customConfig.serverDownMsg,
        "danger"
      )
    );
    dispatch({
      type: UPDATE_EMAIL_ERROR,
    });
  }
};
