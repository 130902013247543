export const getRandomData = (length = 10, rangeStart = 1000, rangeStop = 10000) => {
  let retArr = [];
  for (let i = 0; i < length; i++) {
    retArr.push(Math.floor(Math.random() * rangeStop) + rangeStart);
  }
  return retArr;
};

export const getDateArr = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  let datesArr = [];
  while (start <= end) {
    datesArr.push(start.toISOString().split("T")[0]);
    start.setDate(start.getDate() + 1);
  }
  return datesArr;
};

export const getCountryName = (countryCode) => {
  let countryCodeMapping = {
    "BGD": "Bangladesh",
    "IN": "India",
    "JP": "Japan",
    "SG": "Singapore",
    "SL1": "Srilanka (Softlogic)",
    "SL2": "Srilanka (Damro)",
    "SL3": "Srilanka (Singer)",
    "TH": "Thailand",
    "NP": "Nepal"
  }

  if (countryCodeMapping.hasOwnProperty(countryCode)) {
    return countryCodeMapping[countryCode]
  }

  return countryCode
}

export const getCountryCodeISO = (countryCode) => {
  let countryCodeISOMapping = {
    "BD": "BGD",
    "IN": "IND",
    "JP": "JPN",
    "SG": "SGP",
    "SL1": "LKA",
    "SL2": "LKA",
    "SL3": "LKA",
    "TH": "THA",
    "NP": "NPL",
    "LK": "LKA"
  }

  if (countryCodeISOMapping.hasOwnProperty(countryCode)) {
    return countryCodeISOMapping[countryCode]
  }

  return ""
}

export const getFormatedJSON = (data) => {
  return <pre>
      <code>
          {JSON.stringify(data, null, 4)}
      </code>
  </pre>
}